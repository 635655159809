import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import Currency from '../types/models/currency'
import { logout } from './auth'

interface InitialState {
  currency: Currency[]
}

const initialState: InitialState = {
  currency: [],
}

export const {
  reducer: currencyReducer,
  actions: {
    LIST_CURRENCIES,
    ADD_CURRENCY,
    UPDATE_CURRENCY,
    UPDATE_EXCHANGE_RATES,
  },
} = createSlice({
  name: 'CURRENCY',
  initialState,
  reducers: {
    LIST_CURRENCIES(state, action: PayloadAction<{ currency: Currency[] }>) {
      state.currency = action.payload.currency
    },
    ADD_CURRENCY(state, action: PayloadAction<{ currency: Currency }>) {
      state.currency = [...state.currency, action.payload.currency]
    },
    UPDATE_CURRENCY(state, action: PayloadAction<{ currency: Currency }>) {
      const currencyIndex = state.currency.findIndex(
        (c) => c.id === action.payload.currency.id
      )
      state.currency[currencyIndex] = action.payload.currency
    },
    UPDATE_EXCHANGE_RATES(state, action) {
      //
    },
  },
})

export const getCurrency = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/currency'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_CURRENCIES({
            currency: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addCurrency =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/currency'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADD_CURRENCY({
              currency: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editCurrency =
  (id, payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/currency/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            UPDATE_CURRENCY({
              currency: {
                ...response.data.result.currency,
                from_currency: response.data.result.from_currency,
              },
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateExchangeRates =
  (payload, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/currency/exchange-rates/refresh'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
