import { createSlice } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  completed_payouts: any
  completed_payouts_count: number
  payouts: any[]
  company: any
  orders: any[]
  returns: any[]
  pending_payout: any
}

const initialState: InitialState = {
  completed_payouts: {},
  completed_payouts_count: 0,
  payouts: [],
  company: {},
  orders: [],
  returns: [],
  pending_payout: {},
}

export const {
  reducer: payoutReducer,
  actions: {
    LIST_PAYOUTS,
    LIST_COMPLETED_PAYOUTS,
    GET_PAYOUT_FOR_BRAND,
    CREATED_PAYOUT,
    GET_PAYOUT,
    SET_PAYOUT_STATUS,
    SET_BANK_VERIFIED,
    EDIT_NOTE,
  },
} = createSlice({
  name: 'PAYOUT',
  initialState,
  reducers: {
    LIST_PAYOUTS(state, action) {
      state.payouts = action.payload.payouts
    },
    LIST_COMPLETED_PAYOUTS(state, action) {
      state.completed_payouts[action.payload.page] = action.payload.payouts
      state.completed_payouts_count = action.payload.count
    },
    GET_PAYOUT_FOR_BRAND(state, action) {
      state.company = action.payload.company
      state.orders = action.payload.orders
      state.returns = action.payload.returns
    },
    GET_PAYOUT(state, action) {
      state.pending_payout = action.payload.payout
    },
    CREATED_PAYOUT(state, action) {
      let payoutRow = state.payouts.find((p) => p.id === action.payload.id)
      payoutRow.payout_amount = 0
      payoutRow.return_amount = 0
    },
    SET_PAYOUT_STATUS(state, action) {
      state.completed_payouts[action.payload.page].find(
        (p) => p.id === action.payload.id
      )['status'] = action.payload.status
    },
    SET_BANK_VERIFIED(state, action) {
      state.payouts.find((p) => p.id === action.payload.company_id)[
        'is_verified'
      ] = action.payload.is_verified
    },
    EDIT_NOTE(state, action) {
      state.payouts.find((p) => p.id === action.payload.id)['note'] =
        action.payload.note
    },
  },
})

const getPayouts = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/payout'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_PAYOUTS({
            payouts: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getCompletedPayouts = (params) => (dispatch) => {
  let apiEndpoint = '/admin/payout/completed'
  httpService
    .get(apiEndpoint, {}, params)
    .then((response: any) => {
      console.log(response.data)
      if (response.data.success) {
        dispatch(
          LIST_COMPLETED_PAYOUTS({
            payouts: response.data.result.rows,
            count: response.data.result.count,
            page: params.page,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const createPayout =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/payout`
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        console.log(response.data)
        if (response.data.success) {
          onSuccess()
          dispatch(
            CREATED_PAYOUT({
              id: payload.company_id,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data?.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const getPayoutForBrand = (id) => (dispatch) => {
  let apiEndpoint = `/admin/payout/brand/${id}`
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      console.log(response.data)
      if (response.data.success) {
        dispatch(
          GET_PAYOUT_FOR_BRAND({
            company: response.data.result?.company,
            orders: response.data.result?.orders,
            returns: response.data.result?.returns,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const getPayoutById = (id) => (dispatch) => {
  let apiEndpoint = `/admin/payout/${id}`
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      console.log(response.data)
      if (response.data.success) {
        dispatch(
          GET_PAYOUT({
            payout: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const setPayoutCompleted = (id, page) => (dispatch) => {
  let apiEndpoint = `/admin/payout/completed/${id}`
  httpService
    .put(apiEndpoint, {})
    .then((response: any) => {
      console.log(response.data)
      if (response.data.success) {
        dispatch(
          SET_PAYOUT_STATUS({
            id: id,
            page: page,
            status: 'completed',
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const setPayoutFailed = (id, page) => (dispatch) => {
  let apiEndpoint = `/admin/payout/${id}`
  httpService
    .del(apiEndpoint)
    .then((response: any) => {
      console.log(response.data)
      if (response.data.success) {
        dispatch(
          SET_PAYOUT_STATUS({
            id: id,
            page: page,
            status: 'failed',
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
const bankInfoVerification = (payload) => (dispatch) => {
  let apiEndpoint = `/admin/payout/verify-bank`
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data?.success) {
        dispatch(
          SET_BANK_VERIFIED({
            company_id: payload.company_id,
            is_verified: payload.is_verified,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
export const editNote =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/payout/${payload.id}`
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data?.success) {
          dispatch(
            EDIT_NOTE({
              id: payload.id,
              note: payload.note,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const payoutRedux = {
  getPayouts,
  getCompletedPayouts,
  createPayout,
  getPayoutForBrand,
  getPayoutById,
  setPayoutCompleted,
  setPayoutFailed,
  bankInfoVerification,
  editNote,
}
