import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ActionAccessibility from 'material-ui/svg-icons/action/accessibility'
import { AppThunk } from '../config/store'
import { httpService, history } from '../helpers'
import Company from '../types/models/company'
import Currency from '../types/models/currency'
import Invoice from '../types/models/invoice'
import { logout } from './auth'

interface InitialState {
  current_invoice: { payments: [] }
  invoice: { [key: string]: Invoice[] }
  count: number
  filter: string
  successful: boolean
  retailer: Company[]
  currency: Currency[]
}

const initialState: InitialState = {
  current_invoice: { payments: [] },
  invoice: {},
  count: 0,
  filter: 'all',
  successful: false,
  retailer: [],
  currency: [],
}

export const {
  reducer: invoiceReducer,
  actions: {
    LIST_ALL_INVOICE,
    COLLECT_INVOICE,
    CANCEL_INVOICE,
    ADD_INVOICE,
    GET_INVOICE_OPTIONS,
    FIND_INVOICE_BY_ID,
    EDIT_INVOICE,
    ADD_PAYMENT_ITEM,
  },
} = createSlice({
  name: 'INVOICE',
  initialState,
  reducers: {
    LIST_ALL_INVOICE(
      state,
      action: PayloadAction<{ invoice: any; count: any; filter: string }>
    ) {
      if (state.filter !== action.payload.filter) {
        state.invoice = action.payload.invoice
        state.filter = action.payload.filter
      } else {
        state.invoice = {
          ...state.invoice,
          ...action.payload.invoice,
        }
      }
      state.count = action.payload.count
    },
    COLLECT_INVOICE(
      state,
      action: PayloadAction<{ invoice: Invoice[]; id: string; page: any }>
    ) {
      const invoices = state.invoice[action.payload.page].map((invoice) => {
        if (invoice.id === action.payload.id) {
          return {
            ...invoice,
            status: 'paid',
            successful: true,
          }
        }
        return invoice
      })
      state.invoice[action.payload.page] = invoices
    },
    CANCEL_INVOICE(state, action: PayloadAction<{ id: string; page: any }>) {
      const invoices = state.invoice[action.payload.page].map((invoice) => {
        if (invoice.id === action.payload.id) {
          return {
            ...invoice,
            status: 'cancelled',
          }
        }
        return invoice
      })
      state.invoice[action.payload.page] = invoices
    },
    ADD_INVOICE(state, action: PayloadAction<{ invoice: Invoice }>) {
      // state.invoice = [...state.invoice, action.payload.invoice]
    },
    GET_INVOICE_OPTIONS(
      state,
      action: PayloadAction<{ retailer: Company[]; currency: Currency[] }>
    ) {
      state.retailer = action.payload.retailer
        .filter((r) => r.buy_orders.length > 0)
        .map((r) => ({
          ...r,
          buy_orders: r.buy_orders.sort(
            (a, b) => parseInt(b.id) - parseInt(a.id)
          ),
        }))
      state.currency = action.payload.currency
    },
    FIND_INVOICE_BY_ID(state, action) {
      state.current_invoice = action.payload.invoice
    },
    EDIT_INVOICE(state, action) {
      state.current_invoice = {
        ...state.current_invoice,
        ...action.payload.invoice,
      }
    },
    ADD_PAYMENT_ITEM(state, action) {
      let updatedPayments: any = [...state.current_invoice.payments]
      action.payload.due_date = action.payload.due_date
        .toISOString()
        .substring(0, 10)
      updatedPayments.push(action.payload)
      state.current_invoice = {
        ...state.current_invoice,
        payments: updatedPayments,
      }
    },
  },
})

export const getInvoice =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/invoice'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response?.data.success) {
          dispatch(
            LIST_ALL_INVOICE({
              invoice: { [params.page]: response.data.invoice.rows },
              count: response.data.invoice.count,
              filter: params.filter,
            })
          )
        } else if (response?.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const collectInvoice =
  (page, invoice, handleShowConfirmation): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/invoice'
    httpService
      .post(apiEndpoint, invoice)
      .then((response: any) => {
        if (response?.data.success) {
          handleShowConfirmation()
          dispatch(
            COLLECT_INVOICE({
              invoice: response.data.invoice,
              id: response.data.id,
              page: page,
            })
          )
        } else if (response?.data.logout) {
          dispatch(logout())
        } else {
          alert(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const cancelInvoice =
  (invoice, page): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/invoice/cancel/${invoice.id}`
    httpService
      .put(apiEndpoint, {})
      .then((response: any) => {
        if (response?.data.success) {
          dispatch(
            CANCEL_INVOICE({
              id: invoice.id,
              page: page,
            })
          )
        } else if (response?.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addInvoice =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/invoice/new'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response?.data.success) {
          history.push('/admin/invoice')
          dispatch(
            ADD_INVOICE({
              invoice: response.data.result,
            })
          )
        } else if (response?.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getInvoiceOptions = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/invoice/new'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response?.data.success) {
        dispatch(
          GET_INVOICE_OPTIONS({
            retailer: response.data.result.retailer,
            currency: response.data.result.currency,
          })
        )
      } else if (response?.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const findInvoiceById = (id) => (dispatch) => {
  let apiEndpoint = `/admin/invoice/${id}`
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response?.data.success) {
        dispatch(
          FIND_INVOICE_BY_ID({
            invoice: response.data.result,
          })
        )
      } else if (response?.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const chargeInvoice =
  (id, payload, onSuccess, onFailure) => (dispatch) => {
    let apiEndpoint = `/admin/invoice/${id}/charge`
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response?.data.success) {
          onSuccess()
        } else if (response.data?.message) {
          onFailure(response.data.message)
        } else if (response?.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editInvoice = (id, payload) => (dispatch) => {
  let apiEndpoint = `/admin/invoice/${id}/edit`
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response?.data?.success) {
        dispatch(EDIT_INVOICE({ invoice: response.data.updatedValues }))
      } else if (response?.data?.message) {
        console.log(response.data.message)
      } else if (response?.data?.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addPaymentItem = (payload) => (dispatch) => {
  let apiEndpoint = `/admin/invoice/add-payment`
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response?.data?.success) {
        payload.id = response.data.result
        dispatch(ADD_PAYMENT_ITEM(payload))
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
