import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpService } from '../helpers'
import { AppThunk } from '../config/store'
import { logout } from './auth'

interface InitialState {
  category: any[]
  display_categories: any[]
  current_category: any
  current_display_category: any
  subcategories: any[]
}

const initialState: InitialState = {
  category: [],
  display_categories: [],
  current_category: null,
  current_display_category: null,
  subcategories: [],
}

export const {
  reducer: categoryReducer,
  actions: {
    LIST_CATEGORIES,
    GET_CATEGORY_DETAILS,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    ADD_SUBCATEGORY,
    UPDATE_SUBCATEGORY,
    ADD_DISPLAY_CATEGORY,
    EDIT_DISPLAY_CATEGORY,
    DELETE_DISPLAY_CATEGORY,
    GET_DISPLAY_CATEGORY,
    ADD_DISPLAY_SUBCATEGORY,
    EDIT_DISPLAY_SUBCATEGORY,
    DELETE_DISPLAY_SUBCATEGORY,
  },
} = createSlice({
  name: 'CATEGORY',
  initialState,
  reducers: {
    LIST_CATEGORIES(
      state,
      action: PayloadAction<{
        categories: any[]
        display_categories: any[]
      }>
    ) {
      state.category = action.payload.categories
      state.display_categories = action.payload.display_categories
    },
    GET_CATEGORY_DETAILS(
      state,
      action: PayloadAction<{ current_category: any }>
    ) {
      state.current_category = action.payload.current_category
    },
    ADD_CATEGORY(state, action: PayloadAction<{ category: any }>) {
      state.category = [...state.category, action.payload.category]
    },
    UPDATE_CATEGORY(state, action: PayloadAction<{ category: any }>) {
      const categoryIndex = state.category.findIndex(
        (c) => c.id === action.payload.category.id
      )
      state.category[categoryIndex] = action.payload.category
    },
    ADD_SUBCATEGORY(state, action: PayloadAction<{ subcategory: any }>) {
      if (!state?.current_category) {
        return
      }
      state.current_category.subcategories = [
        ...state.current_category.subcategories,
        action.payload.subcategory,
      ]
    },
    UPDATE_SUBCATEGORY(state, action: PayloadAction<{ subcategory: any }>) {
      if (!state?.current_category) {
        return
      }
      const subcategoryIndex = state.current_category.subcategories.findIndex(
        (s) => s.id === action.payload.subcategory.id
      )
      state.current_category.subcategories[subcategoryIndex] =
        action.payload.subcategory
    },
    ADD_DISPLAY_CATEGORY(
      state,
      action: PayloadAction<{ display_category: any }>
    ) {
      if (!state?.display_categories) {
        return
      }
      state.display_categories.push(action.payload.display_category)
    },
    EDIT_DISPLAY_CATEGORY(
      state,
      action: PayloadAction<{ display_category: any }>
    ) {
      if (!state?.display_categories) {
        return
      }
      state.display_categories.push(action.payload.display_category)
    },
    DELETE_DISPLAY_CATEGORY(state, action: PayloadAction<{ id: number }>) {
      if (!state?.display_categories) {
        return
      }
      const index = state.display_categories.findIndex(
        (dc) => dc.id === action.payload.id
      )
      state.display_categories.splice(index, 1)
    },
    GET_DISPLAY_CATEGORY(
      state,
      action: PayloadAction<{ display_category: any; subcategories: any[] }>
    ) {
      state.current_display_category = action.payload.display_category || null
      state.subcategories = action.payload.subcategories || []
    },
    ADD_DISPLAY_SUBCATEGORY(
      state,
      action: PayloadAction<{ display_subcategory: any }>
    ) {
      state.current_display_category.display_subcategories.push(
        action.payload.display_subcategory
      )
    },
    EDIT_DISPLAY_SUBCATEGORY(
      state,
      action: PayloadAction<{ display_subcategory: any }>
    ) {
      const index = state.current_display_category.display_subcategories.findIndex(
        (dc) => dc.id === action.payload.display_subcategory.id
      )
      state.current_display_category.display_subcategories[index] =
        action.payload.display_subcategory
    },
    DELETE_DISPLAY_SUBCATEGORY(state, action: PayloadAction<{ id: number }>) {
      const index = state.current_display_category.display_subcategories.findIndex(
        (dc) => dc.id === action.payload.id
      )
      state.current_display_category.display_subcategories.splice(index, 1)
    },
  },
})

export const getCategory = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_CATEGORIES({
            categories: response.data.result.categories,
            display_categories: response.data.result.display_categories,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getCategoryById = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          GET_CATEGORY_DETAILS({
            current_category: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addCategory = (payload, onSuccess): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          ADD_CATEGORY({
            category: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editCategory = (id, payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/' + id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          UPDATE_CATEGORY({
            category: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addSubcategory = (id, payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/' + id + '/subcategory'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          ADD_SUBCATEGORY({
            subcategory: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editSubcategory = (id, payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/subcategory/' + id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          UPDATE_SUBCATEGORY({
            subcategory: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addDisplayCategory = (payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/display_category'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          ADD_DISPLAY_CATEGORY({
            display_category: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editDisplayCategory = (payload, id, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/display_category/' + id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        // dispatch(
        //   EDIT_DISPLAY_CATEGORY({
        //     display_category: response.data.result,
        //   })
        // )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteDisplayCategory = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category/display_category/' + id
  httpService
    .del(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_DISPLAY_CATEGORY({
            id: id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getDisplayCategory = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category/display_category/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          GET_DISPLAY_CATEGORY({
            display_category: response.data.result.display_category,
            subcategories: response.data.result.subcategories,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addDisplaySubcategory = (payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/display_subcategory/'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          ADD_DISPLAY_SUBCATEGORY({
            display_subcategory: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editDisplaySubcategory = (payload, onSuccess): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/category/display_subcategory/' + payload.id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          EDIT_DISPLAY_SUBCATEGORY({
            display_subcategory: payload,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteDisplaySubcategory = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/category/display_subcategory/' + id
  httpService
    .del(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_DISPLAY_SUBCATEGORY({
            id: id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
