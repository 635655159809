import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  addresses: { [key: string]: any[] }
  count: number
  current_address: any
}

const initialState: InitialState = {
  addresses: {},
  count: 0,
  current_address: {},
}

export const {
  reducer: addressReducer,
  actions: { LIST_ADDRESSES, GET_ADDRESS_BY_ID, EDIT_ADDRESS, SEARCH_ADDRESS },
} = createSlice({
  name: 'ADDRESS',
  initialState,
  reducers: {
    LIST_ADDRESSES(
      state,
      action: PayloadAction<{
        addresses: { [key: string]: any[] }
        count: number
      }>
    ) {
      state.addresses = { ...state.addresses, ...action.payload.addresses }
      state.count = action.payload.count
    },
    SEARCH_ADDRESS(
      state,
      action: PayloadAction<{
        address: any
      }>
    ) {
      if (action.payload.address) {
        state.addresses = { 0: [action.payload.address] }
        state.count = 1
      } else {
        state.addresses = { 0: [] }
        state.count = 0
      }
    },
    GET_ADDRESS_BY_ID(
      state,
      action: PayloadAction<{
        address: any
      }>
    ) {
      state.current_address = action.payload.address
    },
    EDIT_ADDRESS(
      state,
      action: PayloadAction<{
        address: any
      }>
    ) {
      state.current_address = action.payload.address
    },
  },
})

export const getAddresses =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/address'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_ADDRESSES({
              addresses: { [params.page]: response.data.result.rows },
              count: response.data.result.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getAddressById =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/address/${id}`
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_ADDRESS_BY_ID({
              address: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const searchAddress =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/address/${id}`
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            SEARCH_ADDRESS({
              address: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editAddress =
  (id, payload, setSaveResult): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/address/${id}`
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            type: 'change',
          })
          dispatch(
            EDIT_ADDRESS({
              address: {
                ...payload,
                contact: {
                  ...payload.contact,
                  id: response.data.contact_id,
                },
              },
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            type: 'change',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
