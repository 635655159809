import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../config/store"
import { httpService } from "../helpers"
import Company from "../types/models/company"
import Recommendation from "../types/models/recommendation"
import RecommendationItem from "../types/models/recommendation_item"
import { logout } from "./auth"

interface InitialState {
  segments_all: Recommendation[]
  segments: Recommendation[]
  brands: Company[]
}

const initialState: InitialState = {
  segments_all: [],
  segments: [],
  brands: [],
}

export const {
  reducer: recommendationReducer,
  actions: { LIST_SEGMENTS, ADD_SEGMENT, UPDATE_SEGMENT, DELETE_SEGMENT },
} = createSlice({
  name: "RECOMMENDATION",
  initialState,
  reducers: {
    LIST_SEGMENTS(
      state,
      action: PayloadAction<{
        segments_all: Recommendation[]
        brands: Company[]
      }>
    ) {
      state.segments_all = action.payload.segments_all
      state.brands = action.payload.brands
      const tempSegments = action.payload.segments_all.filter(
        (segment) => segment.recommendation_items.length > 0
      )
      state.segments = tempSegments
    },
    ADD_SEGMENT(
      state,
      action: PayloadAction<{
        segment: Recommendation
        selected_brands: RecommendationItem[]
      }>
    ) {
      state.segments = [
        ...state.segments,
        {
          id: action.payload.segment.id,
          segment: action.payload.segment.segment,
          recommendation_items: action.payload.selected_brands,
        },
      ]
    },
    UPDATE_SEGMENT(
      state,
      action: PayloadAction<{
        segment_id: string
        selected_brands: RecommendationItem[]
      }>
    ) {
      const updatedSegmentsAll = state.segments.map((segment) => {
        if (segment.id === action.payload.segment_id) {
          segment.recommendation_items = action.payload.selected_brands
        }
        return segment
      })
      state.segments = updatedSegmentsAll
    },
    DELETE_SEGMENT(
      state,
      action: PayloadAction<{
        segment_id: string
      }>
    ) {
      const updatedSegments = state.segments.filter(
        (segment) => segment.id !== action.payload.segment_id
      )
      state.segments = updatedSegments
    },
  },
})

export const getSegments = (): AppThunk => (dispatch) => {
  let apiEndpoint = "/admin/recommendation"
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_SEGMENTS({
            segments_all: response.data.result,
            brands: response.data.brands,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addSegment = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = "/admin/recommendation"
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          ADD_SEGMENT({
            segment: response.data.result,
            selected_brands: response.data.recommendation_items,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const updateSegment = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = "/admin/recommendation"
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          UPDATE_SEGMENT({
            segment_id: payload.segment_id,
            selected_brands: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteSegment = (id): AppThunk => (dispatch) => {
  console.log('delete')
  let apiEndpoint = "/admin/recommendation/delete"
  httpService
    .put(apiEndpoint, id)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_SEGMENT({
            segment_id: id.segment_id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
