import React, { Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { crypt } from '../helpers'
import { logout } from '../redux/auth'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const userInfo = crypt.getUserInfo()

  if (
    localStorage.getItem('auth') &&
    localStorage.getItem('login') &&
    Date.now() < userInfo.exp * 1000
  ) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Suspense fallback="loading">
            <Component {...props} />
          </Suspense>
        )}
      />
    )
  } else {
    dispatch(logout())
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
      />
    )
  }
}
