import axios from 'axios'
import config from '../config/config'

export const httpService = {
  get,
  post,
  put,
  del,
}

function get(apiEndpoint, responseType: any = {}, params = {}) {
  // function get(apiEndpoint, responseType = "json", params = {}) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .get(config.baseUrl + apiEndpoint, {
      headers: header,
      responseType: responseType,
      params: params,
    })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}

function post(apiEndpoint, payload) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .post(config.baseUrl + apiEndpoint, payload, { headers: header })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
function put(apiEndpoint, payload) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .put(config.baseUrl + apiEndpoint, payload, { headers: header })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
function del(apiEndpoint, payload = {}) {
  const header = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  return axios
    .delete(config.baseUrl + apiEndpoint, { headers: header, data: payload })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log(err)
    })
}
