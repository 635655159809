import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    // default: {
    //   main: "#00B031",
    //   contrastText: "#ffffff",
    // },
    primary: {
      main: '#54555B',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#30323D',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily:
      '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    h4: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '56px',
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
      color: '#252525',
    },
    body1: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '16px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 13,
      lineHeight: '24px',
      color: '#5E5F61',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '24px',
      color: '#252525',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#0E111C',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 56,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#05C73B',
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#33B1FE',
            boxShadow: 'none',
          },
        },
        outlined: {
          '&:hover': {
            border: '1px solid #B6B6B8',
            backgroundColor: '#FFFFFF',
          },
        },
        outlinedPrimary: {
          '&:hover': {
            border: '1px solid #B6B6B8',
            backgroundColor: '#FFFFFF',
          },
        },
        outlinedSecondary: {
          '&:hover': {
            border: '1px solid #B6B6B8',
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          width: 'auto',
          height: 'auto',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          margin: '8px 0 0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 246,
          height: 36,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: '#5E5F61',
          fontSize: 16,
        },
        formControl: {
          top: -10,
          left: -14,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        },
        paper: {
          margin: 0,
          maxHeight: '100vh',
        },
        paperWidthSm: {
          maxWidth: 580,
        },
        root: {
          '& .DialogHeaderRight': {
            marginLeft: 'auto',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          height: 24,
          backgroundColor: '#042360',
          color: '#C5E0F4',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: 24,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
          justifyContent: 'flex-start',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 11,
          color: '#89898A',
          textTransform: 'uppercase',
          paddingBottom: 4,
        },
        body: {
          borderBottom: 'none',
          verticalAlign: 'top',
          color: '#89898A',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            background: '#FBFBFB',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          width: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginRight: 8,
        },
        textColorPrimary: {
          minWidth: 0,
        },
        textColorSecondary: {
          minWidth: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
  },
})
