import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  referrals: { [key: string]: any[] }
  count: number
}

const initialState: InitialState = {
  referrals: {},
  count: 0,
}

export const {
  reducer: referralReducer,
  actions: { LIST_REFERRALS, ADD_TO_SALESFORCE },
} = createSlice({
  name: 'REFERRAL',
  initialState,
  reducers: {
    LIST_REFERRALS(
      state,
      action: PayloadAction<{
        referrals: { [key: string]: any[] }
        count: number
      }>
    ) {
      state.referrals = { ...state.referrals, ...action.payload.referrals }
      state.count = action.payload.count
    },
    ADD_TO_SALESFORCE(
      state,
      action: PayloadAction<{
        domain: string
        page: number
      }>
    ) {
      let referrals = [...state.referrals[action.payload.page]]
      let referral = referrals.find((r) => r.domain === action.payload.domain)
      referral.added_to_salesforce = !referral.added_to_salesforce

      state.referrals = {
        ...state.referrals,
        [action.payload.page]: referrals,
      }
    },
  },
})

export const getReferrals =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/referral'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_REFERRALS({
              referrals: { [params.page]: response.data.result },
              count: response.data.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addToSalesforce =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/referral/salesforce'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADD_TO_SALESFORCE({
              domain: payload.domain,
              page: payload.page,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
