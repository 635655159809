import { combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { authReducer } from './auth'
import { brandReducer } from './brand'
import { categoryReducer } from './category'
import { currencyReducer } from './currency'
import { invoiceReducer } from './invoice'
import { orderReducer } from './order'
import { organizationReducer } from './organization'
import { recommendationReducer } from './recommendation'
import { retailerReducer } from './retailer'
import { regionReducer } from './region'
import { settingsReducer } from './settings'
import { transactionReducer } from './transaction'
import { returnsReducer } from './returns'
import { shippingReducer } from './shipping'
import { boardReducer } from './board'
import { shipperReducer } from './shipper'
import { addressReducer } from './address'
import { payoutReducer } from './payout'
import { referralReducer } from './referral'
import { peebaDirectReducer } from './peeba_direct'
import { couponReducer } from './coupon'
import { blackListReducer } from './black_list'

const rootReducer = combineReducers({
  authState: authReducer,
  brandState: brandReducer,
  categoryState: categoryReducer,
  currencyState: currencyReducer,
  invoiceState: invoiceReducer,
  orderState: orderReducer,
  organizationState: organizationReducer,
  recommendationState: recommendationReducer,
  retailerState: retailerReducer,
  regionState: regionReducer,
  settingsState: settingsReducer,
  transactionState: transactionReducer,
  returnsState: returnsReducer,
  shippingState: shippingReducer,
  boardState: boardReducer,
  shipperState: shipperReducer,
  addressState: addressReducer,
  payoutState: payoutReducer,
  referralState: referralReducer,
  peebaDirectReducer: peebaDirectReducer,
  couponReducer: couponReducer,
  blackListReducer: blackListReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
