import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import User from '../types/models/user'
import { logout } from './auth'

interface InitialState {
  current_user: User | null
  close_forms: boolean
  users: User[]
  error: any
}

const initialState: InitialState = {
  current_user: null,
  close_forms: false,
  users: [],
  error: null,
}

export const {
  reducer: settingsReducer,
  actions: {
    ADMIN_GET_SETTINGS,
    ADMIN_CREATE_USER,
    ADMIN_UPDATE_USER,
    // HANDLE_ON_CHANGE,
    ADMIN_SENT_CODE,
    ADMIN_SENT_CODE_ERROR,
  },
} = createSlice({
  name: 'SETTINGS',
  initialState,
  reducers: {
    ADMIN_GET_SETTINGS(state, action: PayloadAction<{ current_user: User }>) {
      state.current_user = action.payload.current_user
    },
    ADMIN_CREATE_USER(state, action: PayloadAction<{ new_user: User }>) {
      if (!state.current_user) {
        return console.log(`current_user is undefined`)
      }
      state.close_forms = true
      const existingCurrentUserCompanyUsers = state.current_user.company.users
      state.current_user!.company.users = [
        action.payload.new_user,
        ...(existingCurrentUserCompanyUsers || []),
      ]
    },
    ADMIN_UPDATE_USER(state, action: PayloadAction<any>) {
      if (!state.current_user) {
        return console.log(`current_user is undefined`)
      }
      const users = state.current_user.company.users.map((user) => {
        if (user.username !== action.payload.user.email) {
          return user
        }
        return {
          ...user,
          first_name: action.payload.user.first_name,
          last_name: action.payload.user.last_name,
          role: action.payload.user.role,
        }
      })
      state.current_user.company.users = users
    },
    ADMIN_SENT_CODE(state, action: PayloadAction<any>) {
      state.error = null
    },
    ADMIN_SENT_CODE_ERROR(state, action: PayloadAction<{ error: any }>) {
      state.error = action.payload.error
    },
  },
})

export const getSettings = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/settings'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          ADMIN_GET_SETTINGS({
            current_user: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const createUser = (payload, onSuccess, onFailure): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/settings/user'
  httpService.post(apiEndpoint, payload).then((response: any) => {
    if (response.data.success) {
      onSuccess()
      dispatch(
        ADMIN_CREATE_USER({
          new_user: payload,
        })
      )
    } else if (response.data.logout) {
      dispatch(logout())
    } else {
      onFailure(response.data.message)
    }
  })
}

export const getUserById = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/user/' + id
  httpService.get(apiEndpoint).then((response: any) => {
    // dispatch(ADMIN_GET_USER({}))
  })
}

export const editUser = (payload, onSuccess, onFailure): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/settings/user'
  httpService.put(apiEndpoint, payload).then((response: any) => {
    if (response.data.success) {
      onSuccess()
      dispatch(
        ADMIN_UPDATE_USER({
          user: payload,
        })
      )
    } else if (response.data.logout) {
      dispatch(logout())
    } else {
      onFailure(response.data.error)
    }
  })
}

export const changePassword = (payload, setSaveResult): AppThunk => (
  dispatch
) => {
  let apiEndpoint = '/admin/settings/user/password'
  httpService.put(apiEndpoint, payload).then((response: any) => {
    if (response.data.success) {
      setSaveResult({ open: true, result: 'success', type: 'password' })
      // dispatch(ADMIN_CHANGED_PASSWORD({}))
    } else if (response.data.logout) {
      dispatch(logout())
    } else {
      setSaveResult({ open: true, result: 'error', type: 'password' })
    }
  })
}

export const sendCode = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/settings/user/login'
  httpService.put(apiEndpoint, payload).then((response: any) => {
    if (response.data.success) {
      dispatch(ADMIN_SENT_CODE({}))
    } else if (response.data.logout) {
      dispatch(logout())
    } else {
      dispatch(
        ADMIN_SENT_CODE_ERROR({
          error: response.data.message,
        })
      )
    }
  })
}

export const changeLogin = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/settings/user/login'
  httpService.post(apiEndpoint, payload).then((response: any) => {
    if (response.data.success) {
      // dispatch(ADMIN_CHANGED_LOGIN({}))
    } else if (response.data.logout) {
      dispatch(logout())
    }
  })
}
