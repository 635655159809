import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  shipping: any[]
  regions: any[]
  shippers: any[]
}

const initialState: InitialState = {
  shipping: [],
  regions: [],
  shippers: [],
}

export const {
  reducer: shippingReducer,
  actions: { LIST_SHIPPING, ADD_SHIPPING, EDIT_SHIPPING },
} = createSlice({
  name: 'SHIPPING',
  initialState,
  reducers: {
    LIST_SHIPPING(
      state,
      action: PayloadAction<{
        shipping: []
        regions: []
        shippers: []
      }>
    ) {
      state.shipping = action.payload.shipping
      state.regions = action.payload.regions
      state.shippers = action.payload.shippers
    },
    ADD_SHIPPING(
      state,
      action: PayloadAction<{
        shipping: any
      }>
    ) {
      state.shipping.push(action.payload.shipping)
    },
    EDIT_SHIPPING(
      state,
      action: PayloadAction<{
        shipping: any
      }>
    ) {
      let editIndex = state.shipping.findIndex(
        (shipping) => shipping.id === action.payload.shipping.id
      )
      state.shipping[editIndex] = action.payload.shipping
    },
  },
})

const getShipping = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/shipping'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_SHIPPING({
            shipping: response.data.result.shipping,
            regions: response.data.result.regions,
            shippers: response.data.result.shippers,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const addShipping =
  (payload, setAddShippingOpen): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/shipping'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADD_SHIPPING({
              shipping: response.data.result,
            })
          )
          setAddShippingOpen(false)
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const editShipping =
  (payload, id, setAddShippingOpen): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/shipping/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            EDIT_SHIPPING({
              shipping: payload,
            })
          )
          setAddShippingOpen(false)
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const shippingRedux = {
  getShipping,
  addShipping,
  editShipping,
}
