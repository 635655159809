import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  coupons: { [key: string]: any[] }
  count: number
  orders: any[]
  orders_count: number
  retailers: any[]
  retailers_count: number
}

const initialState: InitialState = {
  coupons: {},
  count: 0,
  orders: [],
  orders_count: 0,
  retailers: [],
  retailers_count: 0,
}

export const {
  reducer: couponReducer,
  actions: {
    ADMIN_COUPON_LIST_COUPONS,
    ADMIN_COUPON_CREATE,
    ADMIN_COUPON_EDIT,
    ADMIN_COUPON_CHANGE_STATUS,
    ADMIN_COUPON_GET_COUPON_ORDERS,
    ADMIN_COUPON_GET_COUPON_RETAILERS,
  },
} = createSlice({
  name: 'REFERRAL',
  initialState,
  reducers: {
    ADMIN_COUPON_LIST_COUPONS(
      state,
      action: PayloadAction<{
        coupons: { [key: string]: any[] }
        count: number
      }>
    ) {
      state.coupons = {
        ...state.coupons,
        ...action.payload.coupons,
      }
      state.count = action.payload.count
    },
    ADMIN_COUPON_CREATE(
      state,
      action: PayloadAction<{
        coupon: any
      }>
    ) {
      let coupons = [...state.coupons[0]]
      coupons.unshift(action.payload.coupon)
      state.coupons = {
        ...state.coupons,
        // eslint-disable-next-line
        [0]: coupons,
      }
    },
    ADMIN_COUPON_EDIT(
      state,
      action: PayloadAction<{
        id: number
        coupon: any
        page: any
      }>
    ) {
      let coupons = [...state.coupons[action.payload.page]]
      let coupon = coupons.find((r) => r.id === action.payload.id)

      coupon.code = action.payload.coupon.code
      coupon.name = action.payload.coupon.name
      coupon.amount_off = action.payload.coupon.amount_off
      coupon.percent_off = action.payload.coupon.percent_off
      coupon.is_free_shipping = action.payload.coupon.is_free_shipping
      coupon.max_shipping_value = action.payload.coupon.max_shipping_value
      coupon.shipping_percent_off = action.payload.coupon.shipping_percent_off
      coupon.currency_id = action.payload.coupon.currency_id
      coupon.region_id = action.payload.coupon.region_id
      coupon.expiry_date = action.payload.coupon.expiry_date
      coupon.max_claim_limit = action.payload.coupon.max_claim_limit
      coupon.is_reusable = action.payload.coupon.is_reusable
      coupon.brand_id = action.payload.coupon.brand_id
      coupon.company = action.payload.coupon.company
      coupon.max_redemption_value = action.payload.coupon.max_redemption_value
      coupon.valid_for_days = action.payload.coupon.valid_for_days
      coupon.minimum_spend = action.payload.coupon.minimum_spend

      state.coupons = {
        ...state.coupons,
        [action.payload.page]: coupons,
      }
    },
    ADMIN_COUPON_CHANGE_STATUS(
      state,
      action: PayloadAction<{
        id: number
        page: number
        status: string
      }>
    ) {
      let coupons = [...state.coupons[action.payload.page]]
      let coupon = coupons.find((r) => r.id === action.payload.id)

      coupon.status = action.payload.status

      state.coupons = {
        ...state.coupons,
        [action.payload.page]: coupons,
      }
    },
    ADMIN_COUPON_GET_COUPON_ORDERS(
      state,
      action: PayloadAction<{
        orders: any[]
        count: number
      }>
    ) {
      state.orders = action.payload.orders
      state.orders_count = action.payload.count
    },
    ADMIN_COUPON_GET_COUPON_RETAILERS(
      state,
      action: PayloadAction<{
        retailers: any[]
        count: number
      }>
    ) {
      state.retailers = action.payload.retailers
      state.retailers_count = action.payload.count
    },
  },
})

export const getCoupons =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon'
    httpService
      .get(apiEndpoint, {}, params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_LIST_COUPONS({
              coupons: { [params.page]: response.data.result },
              count: response.data.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const createCouponCode =
  (payload, handleCloseForm, setMessage): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon/new'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_CREATE({
              coupon: response.data.result,
            })
          )
          handleCloseForm()
        } else if (response.data.duplicate) {
          setMessage('coupon_exists')
        } else if (response.data.brandNotFound) {
          setMessage('brand_not_found')
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editCouponCode =
  (id, payload, handleCloseForm): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon/' + id
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_EDIT({
              id: id,
              coupon: response.data.result,
              page: payload.page,
            })
          )
          handleCloseForm()
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const changeCouponStatus =
  (id, payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon/status/' + id
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_CHANGE_STATUS({
              id: id,
              page: payload.page,
              status: payload.status,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getCouponOrders =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon/orders/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_GET_COUPON_ORDERS({
              orders: response.data.result,
              count: response.data.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getCouponRetailers =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/coupon/retailers/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_COUPON_GET_COUPON_RETAILERS({
              retailers: response.data.result,
              count: response.data.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
