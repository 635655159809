import React from 'react'

const FlexlaneLogo = props => {
	return (
		<svg {...props} viewBox="0 0 19 28" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.92364 7.24556C2.14457 7.60394 1.6168 8.35603 1.54635 9.20882L1.27734 12.4823L17.0207 5.2367C17.7997 4.87832 18.3275 4.12623 18.398 3.27345L18.667 0L2.92364 7.24556Z" />
			<path d="M2.29568 14.9481C1.51208 15.3052 0.982658 16.0573 0.912208 16.9138L0.638672 20.2407L12.1323 14.951C12.9118 14.5922 13.4375 13.8426 13.5075 12.9889L13.7769 9.71509L2.29568 14.9481Z"/>
			<path d="M0 28L0.269462 24.7262C0.3395 23.8726 0.865203 23.1229 1.64468 22.7642L8.88662 19.4315L8.61756 22.7054C8.54752 23.559 8.02182 24.3086 7.24234 24.6674L0 28Z"/>
		</svg>
	)
}

export default FlexlaneLogo
