import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import OrderActivityReply from '../types/models/order_activity_reply.d'
import OrderFile from '../types/models/order_file'
import { logout } from './auth'

interface InitialState {
  order: any
  count: number
  current_order: any
  activity_feed_reply: Partial<OrderActivityReply> | null
  created_by: string | null
}

const initialState: InitialState = {
  order: {},
  count: 0,
  current_order: null,
  activity_feed_reply: null,
  created_by: null,
}

export const {
  reducer: orderReducer,
  actions: {
    LIST_ALL_ORDER,
    GET_ORDER_DETAILS,
    EDIT_ORDER,
    ADD_ORDER_FILE,
    DELETED_FILE,
    DELETED_SHIPMENT,
    DELETED_PARCEL,
    REJECT_ORDER,
    GET_ORDER_DETAILS_LABEL,
    CREATE_LABEL,
    APPROVE_ORDER,
  },
} = createSlice({
  name: 'ORDER',
  initialState,
  reducers: {
    LIST_ALL_ORDER(
      state,
      action: PayloadAction<{
        order: any
        count: number
      }>
    ) {
      state.order = action.payload.order
      state.count = action.payload.count
    },
    GET_ORDER_DETAILS(state, action: PayloadAction<{ current_order: any }>) {
      state.current_order = action.payload.current_order
      const activity_feed_reply: Partial<OrderActivityReply> =
        action.payload.current_order.order_activities.reduce(
          (activityFeedReply, orderActivity) => {
            activityFeedReply[orderActivity.id] = { open: false }
            orderActivity.order_activity_replies.forEach((reply) => {
              activityFeedReply[orderActivity.id][reply.id] = { open: false }
            })
            return activityFeedReply
          },
          {}
        )
      state.activity_feed_reply = activity_feed_reply
    },
    EDIT_ORDER(state, action: PayloadAction<{ current_order: any }>) {
      state.current_order = {
        ...state.current_order,
        status: action.payload.current_order.status,
        commission: action.payload.current_order.commission,
        target_shipment_date: action.payload.current_order.estimatedDate,
        actual_shipment_date: action.payload.current_order.shipmentDate,
        delivered_date: action.payload.current_order.deliveredDate,
        reject_date: action.payload.current_order.rejectedDate,
      }
    },
    APPROVE_ORDER(state) {
      state.current_order.status = 'new'
    },
    ADD_ORDER_FILE(
      state,
      action: PayloadAction<{ order_file: OrderFile; created_by: string }>
    ) {
      const existingCurrentOrderFiles = state?.current_order?.order_files || []
      state.current_order!.order_files = [
        ...existingCurrentOrderFiles,
        action.payload.order_file,
      ]
      state.created_by = action.payload.created_by
    },
    DELETED_FILE(state, action: PayloadAction<{ order_file_id: string }>) {
      const existingCurrentOrderFiles = state.current_order?.order_files || []
      const updatedCurrentOrderFiles = existingCurrentOrderFiles.filter(
        (orderFile) => orderFile.id !== action.payload.order_file_id
      )
      state.current_order!.order_files = updatedCurrentOrderFiles
    },
    DELETED_SHIPMENT(state, action: PayloadAction<{ shipment_id: string }>) {
      const existingCurrentShipments = state.current_order?.shipments || []
      const updatedCurrentShipments = existingCurrentShipments.filter(
        (shipment) => shipment.id !== action.payload.shipment_id
      )
      state.current_order!.shipments = updatedCurrentShipments
    },
    DELETED_PARCEL(state, action: PayloadAction<{ parcel_id: string }>) {
      const existingCurrentShipments = state.current_order?.shipments || []
      for (let shipment of existingCurrentShipments) {
        shipment.parcels = shipment.parcels.filter(
          (parcel) => parcel.id !== action.payload.parcel_id
        )
      }
      state.current_order!.shipments = existingCurrentShipments
    },
    REJECT_ORDER(state, action: PayloadAction<{}>) {
      state.current_order.status = 'rejected'
    },
    GET_ORDER_DETAILS_LABEL(
      state,
      action: PayloadAction<{ current_order: any }>
    ) {
      state.current_order = action.payload.current_order
    },
    CREATE_LABEL(
      state,
      action: PayloadAction<{
        shipping_label_url: string
        shipping_invoice_url: string
      }>
    ) {
      state.current_order.shipping_label_url = action.payload.shipping_label_url
      state.current_order.shipping_invoice_url =
        action.payload.shipping_invoice_url
    },
  },
})

export const getOrder =
  (params): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_ALL_ORDER({
              order: { [params.page]: response.data.order.rows },
              count: response.data.order.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
export const getOrderById =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_ORDER_DETAILS({
              current_order: response.data.current_order,
            })
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editOrder =
  (id, payload, onSuccess): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            EDIT_ORDER({
              current_order: payload,
            })
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const approveOrder =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id + '/approve'
    httpService
      .post(apiEndpoint, {})
      .then((response: any) => {
        if (response.data.success) {
          dispatch(APPROVE_ORDER())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
export const rejectOrder =
  (id, payload): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id + '/reject'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(REJECT_ORDER({}))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addOrderFile =
  (payload, id, onSuccess): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id

    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            ADD_ORDER_FILE({
              order_file: response.data.result,
              created_by: response.data.created_by,
            })
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getOrderFile =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/order/file/' + id
    httpService
      .get(apiEndpoint, 'blob')
      .then((response: any) => {
        const file = new Blob([response.data], { type: response.data.type })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const deleteProductFile =
  (id, payload): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/order/file/' + id
    httpService
      .del(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            DELETED_FILE({
              order_file_id: id,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const deleteShipment =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/delete-shipment/' + id
    httpService
      .del(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            DELETED_SHIPMENT({
              shipment_id: id,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const deleteParcel =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/delete-parcel/' + id
    httpService
      .del(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            DELETED_PARCEL({
              parcel_id: id,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getOrderByIdLabel =
  (id): AppThunk =>
  (dispatch) => {
    const apiEndpoint = `/admin/order/${id}/label`
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_ORDER_DETAILS_LABEL({
              current_order: {
                ...response.data.current_order,
                addresses: response.data.addresses,
                regions: response.data.regions,
              },
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const createShippingLabel =
  (id, payload, onFailure): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/' + id + '/label'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            CREATE_LABEL({
              shipping_label_url: response.data.shipping_label_url,
              shipping_invoice_url: response.data.shipping_invoice_url,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const generate =
  (onSuccess): AppThunk =>
  (dispatch) => {
    const apiEndpoint = '/admin/order/generate-ids'
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
