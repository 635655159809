import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpService, history } from '../helpers'
import Company from '../types/models/company'
import Currency from '../types/models/currency'
import { AppThunk } from '../config/store'
import { logout } from './auth'

interface InitialState {
  black_list: { [key: string]: any[] }
  count: number
}

const initialState: InitialState = {
  black_list: {},
  count: 0,
}

export const {
  reducer: blackListReducer,
  actions: { LIST_BLACK_LIST, UPDATE_BLACK_LIST },
} = createSlice({
  name: 'BLACK_LIST',
  initialState,
  reducers: {
    LIST_BLACK_LIST(
      state,
      action: PayloadAction<{ black_list: any; count: any }>
    ) {
      state.black_list = action.payload.black_list
      state.count = action.payload.count
    },
    UPDATE_BLACK_LIST(state, action: PayloadAction<{ black_list: any }>) {
      const list: any = state.black_list
      for(let page in list) {
        list[page] = list[page].map((item) => {
          if (item.id == action.payload.black_list?.id) {
            item.is_checkout_blocked = !item.is_checkout_blocked
          }
          return item
        })
      }
      state.black_list = list
    },
  },
})

export const getBlackList =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/black-list'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_BLACK_LIST({
              black_list: { [params.page]: response.data.result.rows },
              count: response.data.result.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateBlackList =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/black_list'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            UPDATE_BLACK_LIST({
              black_list: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
