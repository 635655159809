import { configureStore, Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"

import rootReducer, { RootState } from "../redux/rootReducer"

const store = configureStore({
  reducer: rootReducer,
})

//@ts-ignore for some reason module.hot type is not missing
if (process.env.NODE_ENV === "development" && module.hot) {
  //@ts-ignore
  module.hot.accept("../redux/rootReducer", () => {
    const newRootReducer = require("../redux/rootReducer").default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
