import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import { LocalizeProvider } from 'react-localize-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import './App.css'
import { history } from './helpers'
import translations from './translations.json'

import { theme } from './theme'

import { PrivateRoute } from './components/PrivateRoute'
//Weird lazy/suspense error
import Login from './components/login'

const Brand = React.lazy(() => import('./components/brand'))
const AddBrand = React.lazy(() => import('./components/brand/addBrand'))
const BrandDetail = React.lazy(() => import('./components/brand/brandDetail'))
const Currency = React.lazy(() => import('./components/currency'))
const Category = React.lazy(() => import('./components/category'))
const CategoryDetail = React.lazy(
  () => import('./components/category/categoryDetail')
)
const DisplayCategoryDetail = React.lazy(
  () => import('./components/category/DisplayCategoryDetail')
)
const Invoice = React.lazy(() => import('./components/invoice'))
const InvoiceDetails = React.lazy(
  () => import('./components/invoice/InvoiceDetails')
)
const AddInvoice = React.lazy(() => import('./components/invoice/addInvoice'))
const Recommendation = React.lazy(() => import('./components/recommendation'))
const Home = React.lazy(() => import('./components/home'))
const Retailer = React.lazy(() => import('./components/retailer'))
const Organization = React.lazy(() => import('./components/organization'))
const OrganizationDetail = React.lazy(() => import('./components/organization/detail'))
const AddRetailer = React.lazy(
  () => import('./components/retailer/addRetailer')
)
const AddRetailerByPeeba = React.lazy(
  () => import('./components/retailer/addRetailerByPeeba')
)
const RetailerDetail = React.lazy(
  () => import('./components/retailer/retailerDetail')
)
const Order = React.lazy(() => import('./components/order'))
const OrderDetail = React.lazy(() => import('./components/order/orderDetail'))
const OrderLabel = React.lazy(() => import('./components/order/orderLabel'))
const Returns = React.lazy(() => import('./components/returns/Returns'))
const ReturnDetails = React.lazy(
  () => import('./components/returns/ReturnDetails')
)
const Transaction = React.lazy(() => import('./components/transaction'))
//const Login = React.lazy(() => import('./components/login'))
const ForgotPassword = React.lazy(
  () => import('./components/login/forgotPassword')
)
const Settings = React.lazy(() => import('./components/settings'))
const RegionMain = React.lazy(() => import('./components/region/RegionMain'))
const ShippingMain = React.lazy(
  () => import('./components/shipping/ShippingMain')
)
const Board = React.lazy(() => import('./components/board'))
const BoardDetail = React.lazy(() => import('./components/board/boardDetail'))
const Shipper = React.lazy(() => import('./components/shipper'))
const Address = React.lazy(() => import('./components/address'))
const AddressDetails = React.lazy(
  () => import('./components/address/addressDetails')
)
const PayoutList = React.lazy(() => import('./components/payout/PayoutList'))
const PayoutDetails = React.lazy(
  () => import('./components/payout/PayoutDetails')
)
const PendingPayoutDetails = React.lazy(
  () => import('./components/payout/PendingPayoutDetails')
)
const Referral = React.lazy(() => import('./components/referral/referral'))
const PeebaDirect = React.lazy(
  () => import('./components/peeba_direct/peebaDirect')
)
const BlackList = React.lazy(
  () => import('./components/black_list/blackList')
)
const Coupon = React.lazy(() => import('./components/coupon/coupon'))
const CouponDetails = React.lazy(
  () => import('./components/coupon/couponDetails')
)

class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <LocalizeProvider
          initialize={{
            languages: [
              { name: 'English', code: 'en' },
              { name: 'Chinese (Simplified)', code: 'zh_CN' },
              { name: 'Chinese (Traditional)', code: 'zh_TW' },
            ],
            translation: translations,
            options: {
              renderToStaticMarkup,
              defaultLanguage: localStorage.getItem('language_code') || 'en',
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router history={history}>
                <Switch>
                  <PrivateRoute exact path="/home" component={Home} />
                  <PrivateRoute
                    exact
                    path="/admin/organization"
                    component={Organization}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/organization/:id"
                    component={OrganizationDetail}
                  />
                  <PrivateRoute exact path="/admin/brand" component={Brand} />
                  <PrivateRoute
                    exact
                    path="/admin/brand/new"
                    component={AddBrand}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/brand/:id"
                    component={BrandDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/retailer"
                    component={Retailer}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/retailer/new"
                    component={AddRetailer}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/retailer/new-by-peeba"
                    component={AddRetailerByPeeba}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/retailer/:id"
                    component={RetailerDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/currency"
                    component={Currency}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/transaction"
                    component={Transaction}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/category"
                    component={Category}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/category/:id"
                    component={CategoryDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/display_category/:id"
                    component={DisplayCategoryDetail}
                  />
                  <PrivateRoute exact path="/admin/order" component={Order} />
                  <PrivateRoute
                    exact
                    path="/admin/invoice"
                    component={Invoice}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/invoice/new"
                    component={AddInvoice}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/invoice/:id"
                    component={InvoiceDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/order/:id"
                    component={OrderDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/order/:id/label"
                    component={OrderLabel}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/settings/:setting"
                    component={Settings}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/returns"
                    component={Returns}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/returns/:id"
                    component={ReturnDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/payout"
                    component={PayoutList}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/payout/:id"
                    component={PendingPayoutDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/payout/brand/:id"
                    component={PayoutDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/recommendation"
                    component={Recommendation}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/region"
                    component={RegionMain}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/shipping"
                    component={ShippingMain}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/board/:id"
                    component={BoardDetail}
                  />
                  <PrivateRoute exact path="/admin/board" component={Board} />
                  <PrivateRoute
                    exact
                    path="/admin/shipper"
                    component={Shipper}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/address"
                    component={Address}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/address/:id"
                    component={AddressDetails}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/referral"
                    component={Referral}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/peeba-direct"
                    component={PeebaDirect}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/black-list"
                    component={BlackList}
                  />
                  <PrivateRoute exact path="/admin/coupon" component={Coupon} />
                  <PrivateRoute
                    exact
                    path="/admin/coupon/:id"
                    component={CouponDetails}
                  />
                  <Route exact path="/" component={Login} />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                  />
                </Switch>
              </Router>
            </LocalizationProvider>
          </ThemeProvider>
        </LocalizeProvider>
      </StyledEngineProvider>
    )
  }
}

export default App
