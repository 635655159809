import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../config/store"
import { httpService } from "../helpers"
import Transaction from "../types/models/transaction"
import { logout } from "./auth"

interface InitialState {
  transactions: { [key: string]: Transaction[] }
  count: number
}

const initialState: InitialState = {
  transactions: {},
  count: 0,
}

export const {
  reducer: transactionReducer,
  actions: { RESET_TRANSACTION, LIST_TRANSACTIONS },
} = createSlice({
  name: "TRANSACTION",
  initialState,
  reducers: {
    RESET_TRANSACTION(state, action: PayloadAction<{}>) {
      state = initialState
    },
    LIST_TRANSACTIONS(
      state,
      action: PayloadAction<{ transactions: { [key: string]: Transaction[] }; count: number }>
    ) {
      state.transactions = { ...state.transactions, ...action.payload.transactions } 
      state.count = action.payload.count
    },
  },
})

export const resetTransaction = (): AppThunk => (dispatch) => {
  dispatch(RESET_TRANSACTION({}))
}

export const getTransaction = (params): AppThunk => (dispatch) => {
  let apiEndpoint = "/admin/transaction"
  httpService
    .get(apiEndpoint, "json", params)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_TRANSACTIONS({
            transactions: { [params.page]: response.data.result.rows },
            count: response.data.result.count,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
