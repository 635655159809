import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  regions: any[]
}

const initialState: InitialState = {
  regions: [],
}

export const {
  reducer: regionReducer,
  actions: { LIST_REGIONS, ADD_REGION, EDIT_REGION },
} = createSlice({
  name: 'REGION',
  initialState,
  reducers: {
    LIST_REGIONS(
      state,
      action: PayloadAction<{
        regions: []
      }>
    ) {
      state.regions = action.payload.regions
    },
    ADD_REGION(
      state,
      action: PayloadAction<{
        region: any
      }>
    ) {
      state.regions.push(action.payload.region)
    },
    EDIT_REGION(
      state,
      action: PayloadAction<{
        region: any
      }>
    ) {
      let editIndex = state.regions.findIndex(
        (region) => region.id === action.payload.region.id
      )
      state.regions[editIndex] = action.payload.region
    },
  },
})

const getRegions = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/region'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_REGIONS({
            regions: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const addRegion = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/region'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          ADD_REGION({
            region: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const editRegion = (payload, id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/region/' + id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          EDIT_REGION({
            region: payload,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const regionRedux = {
  getRegions,
  addRegion,
  editRegion,
}
