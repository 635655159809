import Company from '../types/models/company'
import Currency from '../types/models/currency'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../config/store'
import { history, httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  brands: { [key: string]: Company[] }
  count: number
  current_brand: Company | undefined
  currencies: Currency[] | undefined
  roles: string[]
  regions: any[]
  search_brands: any[]
  selected_brand: string
  peeba_direct_config: any
}

const initialState: InitialState = {
  brands: {},
  count: 0,
  current_brand: undefined,
  currencies: undefined,
  roles: [],
  regions: [],
  search_brands: [],
  selected_brand: '',
  peeba_direct_config: undefined,
}

export const {
  reducer: brandReducer,
  actions: {
    RESET_BRAND,
    LIST_BRANDS,
    GET_BRAND_DETAILS,
    GET_BRAND_SUGGESTIONS,
    UPDATE_ADD_BRAND,
    EDIT_BRAND,
    EDIT_BRAND_COUPON_CONFIG,
    ADD_BRAND_USER,
    UPDATE_BRAND_USER,
    UPDATE_BRAND_CONTACT,
    GET_ROLES,
    ACTIVATE_BRAND,
    DEACTIVATE_BRAND,
  },
} = createSlice({
  name: 'BRAND',
  initialState,
  reducers: {
    RESET_BRAND(state) {
      state = initialState
    },
    LIST_BRANDS(state, action: PayloadAction<{ brands: any; count: any }>) {
      state.brands = action.payload.brands
      state.count = action.payload.count
    },
    GET_BRAND_DETAILS(
      state,
      action: PayloadAction<{
        current_brand: any
        regions: any
        peeba_direct_config: any
      }>
    ) {
      state.current_brand = action.payload.current_brand
      state.regions = action.payload.regions
      state.peeba_direct_config = action.payload.peeba_direct_config
    },
    GET_BRAND_SUGGESTIONS(
      state,
      action: PayloadAction<{
        search_brands: any
      }>
    ) {
      state.search_brands = action.payload.search_brands
    },
    UPDATE_ADD_BRAND(
      state,
      action: PayloadAction<{ currency: any; regions: any }>
    ) {
      state.currencies = action.payload.currency
      state.regions = action.payload.regions
    },
    EDIT_BRAND(state, action: PayloadAction<{ brand: any }>) {
      state.current_brand = {
        ...state.current_brand,
        ...action.payload.brand,
      }
    },
    EDIT_BRAND_COUPON_CONFIG(
      state,
      action: PayloadAction<{ peeba_direct_config: any }>
    ) {
      state.peeba_direct_config = action.payload.peeba_direct_config
    },
    ADD_BRAND_USER(state, action: PayloadAction<{ contact: any; user: any }>) {
      if (!state.current_brand) {
        return
      }
      state.current_brand.primary_contact = action.payload.contact
      state.current_brand.contacts = [
        action.payload.contact,
        ...state.current_brand.contacts,
      ]
      state.current_brand.users = [
        action.payload.contact,
        ...state.current_brand.users,
      ]
    },
    UPDATE_BRAND_USER(state, action: PayloadAction<{ user: any }>) {
      if (!state.current_brand) {
        return
      }
      let users = state.current_brand.users
      for (let i = 0; i < users.length; i++) {
        if (users[i].username === action.payload.user.email) {
          users[i].first_name = action.payload.user.first_name
          users[i].last_name = action.payload.user.last_name
          users[i].role_id = action.payload.user.role_id
          break
        }
      }
      state.current_brand.users = users
    },
    UPDATE_BRAND_CONTACT(state, action: PayloadAction<{ contact: any }>) {
      if (!state.current_brand) {
        return
      }
      state.current_brand.primary_contact = action.payload.contact
    },
    GET_ROLES(state, action: PayloadAction<{ roles: any }>) {
      state.roles = action.payload.roles
    },
    ACTIVATE_BRAND(state) {
      if (state.current_brand) {
        state.current_brand.status = 'active'
      }
    },
    DEACTIVATE_BRAND(state) {
      if (state.current_brand) {
        state.current_brand.status = 'inactive'
      }
    },
  },
})

export const generateSlugs = () => () => {
  let apiEndpoint = '/admin/brand/generateSlugs'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {})
    .catch((err) => {
      console.log(err)
    })
}

export const resetBrand = (): AppThunk => (dispatch) => {
  dispatch(RESET_BRAND())
}

export const getBrand =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_BRANDS({
              brands: { [params.page]: response.data.result.rows },
              count: response.data.result.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getBrandById =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_BRAND_DETAILS({
              current_brand: response.data.result.brand,
              regions: response.data.result.regions,
              peeba_direct_config: response.data.result.peeba_direct_config,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const loginAsBrand =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/login/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          if (window.location.host.match(/static-admin/)) {
            window.open('https://brand.peeba.com/?token=' + response.data.result)
          } else if (window.location.host.match(/dev.admin.peeba.xyz/)){
            window.open('https://dev.brand.peeba.xyz/?token=' + response.data.result)
          } else {
            window.open('https://brand-client-staging-j275taoz7q-de.a.run.app/?token=' + response.data.result)
          }
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addBrand =
  (payload, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          history.push('/admin/brand')
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const prepAddBrand = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/brand/new'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      dispatch(
        UPDATE_ADD_BRAND({
          currency: response.data.result.currencies,
          regions: response.data.result.regions,
        })
      )
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editBrand =
  (id, payload, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            EDIT_BRAND({
              brand: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          //onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editBrandCouponConfig =
  (id, payload, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/edit-coupon-config/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            EDIT_BRAND_COUPON_CONFIG({
              peeba_direct_config: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          //onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/user'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            ADD_BRAND_USER({
              user: response.data.result,
              contact: response.data.contact,
            })
          )
        } else if (response.data.mailer) {
          onFailure(response.data.error)
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/update-user'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            UPDATE_BRAND_USER({
              user: payload,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const resetPassword =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/reset'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateContact =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/update-contact'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            UPDATE_BRAND_CONTACT({
              contact: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getRoles = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/brand/user'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          GET_ROLES({
            roles: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addProduct =
  (payload, brandId, setSaveResult): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/brand/${brandId}/product`
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          setSaveResult({
            open: true,
            result: 'success',
            disabled: false,
            type: 'change',
          })
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          setSaveResult({
            open: true,
            result: 'error',
            disabled: false,
            type: 'change',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const activateBrand =
  (id, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/brand/${id}/activate`
    httpService
      .put(apiEndpoint, {})
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          ACTIVATE_BRAND()
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          alert(
            'Quality Check Failed! Missing (one or all): Website, Feature Photo, Order Notification Email'
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const deactivateBrand =
  (id, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/brand/${id}/deactivate`
    httpService
      .put(apiEndpoint, {})
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          DEACTIVATE_BRAND()
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const referralLookup =
  (id): AppThunk =>
  (dispatch) => {
    console.log('in thunk')
    let apiEndpoint = `/admin/brand/${id}/referral`
    httpService
      .put(apiEndpoint, {})
      .then((response: any) => {
        if (response.data.success) {
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getGlobalBrandSuggestions = (payload) => (dispatch) => {
  httpService
    .get('/admin/brand/search/global-brand-suggestions', {}, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          GET_BRAND_SUGGESTIONS({
            search_brands: response.data.result.map((brand) => {
              return { label: brand.name, id: brand.id }
            }),
          })
        )
      }
    })
    .catch((e) => console.log(e))
}

export const createBrand =
  (payload, onFailure, onSuccess, setSelectedOption, setOpen): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/brand/create-brand'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          setOpen(false)
          onSuccess(response.data.result.name)
          setSelectedOption(response.data.new_brand_id)
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
