import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService, history } from '../helpers'
import Company from '../types/models/company'
import Currency from '../types/models/currency'
import RetailChannel from '../types/models/retail_channel'
import User from '../types/models/user'
import { UpdateUserPayload } from '../types/sharedTypes'
import { logout } from './auth'

interface InitialState {
  retailersByPage: RetailersByPage | null
  count: number
  current_retailer: Company | null
  currencies: Currency[] | null
  retail_channels: RetailChannel[] | null
  pending: []
  users: []
  cartsByPage: any
  cartCount: number
  ordersByPage: any
  orderCount: number
}

interface RetailersByPage {
  [page: string]: Company[]
}

const initialState: InitialState = {
  retailersByPage: null,
  count: 0,
  current_retailer: {} as Company,
  currencies: [],
  retail_channels: null,
  pending: [],
  users: [],
  cartsByPage: {},
  cartCount: 0,
  ordersByPage: {},
  orderCount: 0,
}

export const {
  reducer: retailerReducer,
  actions: {
    RESET_RETAILER,
    LIST_RETAILERS,
    GET_RETAILER_DETAILS,
    UPDATE_ADD_RETAILER,
    EDIT_RETAILER,
    UPDATE_RETAILER_USER,
    ADD_RETAILER_USER,
    GET_RETAILER_CART,
    GET_RETAILER_ORDER,
    GET_RETAILER_PENDING,
    GET_RETAILER_USERS,
    RESET_RETAILER_CART,
    UPDATE_BLACK_LIST,
    UNBLOCK_CHAT,
  },
} = createSlice({
  name: 'RETAILER',
  initialState,
  reducers: {
    RESET_RETAILER(state, action: PayloadAction<{}>) {
      state = initialState
    },
    LIST_RETAILERS(
      state,
      action: PayloadAction<{ retailersByPage: RetailersByPage; count: number }>
    ) {
      state.retailersByPage = action.payload.retailersByPage
      state.count = action.payload.count
    },

    GET_RETAILER_ORDER(
      state,
      action: PayloadAction<{
        count: number
        rows: any
        page: number
      }>
    ) {
      state.orderCount = action.payload.count
      state.ordersByPage[action.payload.page + 1] = action.payload.rows
    },

    GET_RETAILER_CART(
      state,
      action: PayloadAction<{
        count: number
        rows: any
        page: number
      }>
    ) {
      state.cartCount = action.payload.count
      state.cartsByPage[action.payload.page + 1] = action.payload.rows
    },
    GET_RETAILER_DETAILS(
      state,
      action: PayloadAction<{
        current_retailer: Company
        pending: any
        is_chat_blocked: boolean
      }>
    ) {
      let pending_count = 0
      let retailer: any = {}
      retailer = action.payload.current_retailer

      for (let order of action.payload.pending) {
        if (!order.shipping_address.is_verified) {
          pending_count++
        }
      }
      if (!retailer.is_verified && retailer.business_registration_file) {
        pending_count++
      }

      retailer.pending_orders = action.payload.pending
      retailer.pending_count = pending_count
      retailer.is_chat_blocked = action.payload.is_chat_blocked
      state.current_retailer = retailer
    },
    UPDATE_ADD_RETAILER(
      state,
      action: PayloadAction<{
        currencies: Currency[]
        retail_channels: RetailChannel[]
      }>
    ) {
      state.currencies = action.payload.currencies
      state.retail_channels = action.payload.retail_channels
    },
    EDIT_RETAILER(state, action: PayloadAction<{ retailer: any }>) {
      state.current_retailer = {
        ...state.current_retailer,
        ...action.payload.retailer,
      }
    },
    UPDATE_RETAILER_USER(state, action: PayloadAction<UpdateUserPayload>) {
      const users = state.current_retailer!.users.map((user) => {
        if (user.username !== action.payload.user.email) {
          return user
        }
        return {
          ...user,
          username: action.payload.user.email,
          first_name: action.payload.user.first_name,
          last_name: action.payload.user.last_name,
          role_id: action.payload.user.role_id,
        }
      })
      state.current_retailer!.users = users
    },
    ADD_RETAILER_USER(
      state,
      action: PayloadAction<{
        user: User
      }>
    ) {
      const currentRetailerUsers = state.current_retailer?.users || []
      state.current_retailer!.users = [
        action.payload.user,
        ...currentRetailerUsers,
      ]
    },

    GET_RETAILER_PENDING(
      state,
      action: PayloadAction<{
        pending: any
      }>
    ) {
      state.pending = action.payload.pending
    },
    GET_RETAILER_USERS(
      state,
      action: PayloadAction<{
        users: any
      }>
    ) {
      state.users = action.payload.users
    },
    RESET_RETAILER_CART(state, action: PayloadAction<{}>) {
      state.cartsByPage = {}
    },
    UPDATE_BLACK_LIST(state, action: PayloadAction<{ black_list: any }>) {
      state.current_retailer!.black_list = action.payload.black_list
    },
    UNBLOCK_CHAT(state, action: PayloadAction<{}>) {
      let retailer: any = {}
      retailer = state.current_retailer
      retailer!.is_chat_blocked = false
      state.current_retailer = retailer
    },
  },
})

export const resetRetailer = (): AppThunk => (dispatch) => {
  dispatch(RESET_RETAILER({}))
}

export const getRetailer =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_RETAILERS({
              retailersByPage: { [params.page]: response.data.result.rows },
              count: response.data.result.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getRetailerById =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_RETAILER_DETAILS({
              current_retailer: response.data.result,
              pending: response.data.pending,
              is_chat_blocked: response.data.is_chat_blocked,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addRetailer =
  (payload, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          history.push('/admin/retailer')
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addRetailerForSaler =
  (payload, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/add_by_saler'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          history.push('/admin/retailer')
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const prepAddRetailer = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/retailer/new'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      dispatch(
        UPDATE_ADD_RETAILER({
          currencies: response.data.currency,
          retail_channels: response.data.channel,
        })
      )
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editRetailer =
  (id, payload, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            EDIT_RETAILER({
              retailer: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          //onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/user'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            ADD_RETAILER_USER({
              user: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/user'
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            UPDATE_RETAILER_USER({
              user: payload,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          // onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const resetPassword =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/reset'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getCartItems =
  (params, payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/retailer/${params}/cart`
    httpService
      .get(apiEndpoint, {}, payload)
      .then((response: any) => {
        console.log(response.data)
        if (response.data.success) {
          dispatch(
            GET_RETAILER_CART({
              count: Math.ceil(response.data.result.count / 20),
              rows: response.data.result.rows,
              page: payload.page,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getRetailerOrder =
  (params, payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/retailer/${params}/order`
    httpService
      .get(apiEndpoint, {}, payload)
      .then((response: any) => {
        console.log(response.data)
        if (response.data.success) {
          dispatch(
            GET_RETAILER_ORDER({
              count: Math.ceil(response.data.result.count / 20),
              rows: response.data.result.rows,
              page: payload.page,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getRetailerPending =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/retailer/${params}/pending`
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        console.log(response.data)

        if (response.data.success) {
          dispatch(
            GET_RETAILER_PENDING({
              pending: response.data.pending,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const getRetailerUsers =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = `/admin/retailer/${params}/user`
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          console.log(response.data)

          dispatch(
            GET_RETAILER_USERS({
              users: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateBlackList =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/black_list'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            UPDATE_BLACK_LIST({
              black_list: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const unblockChat =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/retailer/unblock-chat'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(UNBLOCK_CHAT({}))
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const resetRetailerCart = (): AppThunk => (dispatch) => {
  dispatch(RESET_RETAILER_CART({}))
}
