import Nacl from "tweetnacl"
import NaclUtil from "tweetnacl-util"

export const crypt = {
  hash,
  getEphemKeys,
  symmetricEncrypt,
  symmetricDecrypt,
  asymmetricEncrypt,
  asymmetricDecrypt,
  getUserInfo,
}

function hash(message) {
  return NaclUtil.encodeBase64(Nacl.hash(NaclUtil.decodeUTF8(message)))
}

//SHA512
function symmetricEncrypt(message, authKey) {
  let utf8Message = NaclUtil.decodeUTF8(message)
  let nonce = Nacl.randomBytes(Nacl.secretbox.nonceLength)
  return Nacl.secretbox(utf8Message, nonce, authKey)
}

//SHA512
function symmetricDecrypt(encryptedMessage, nonce, authKey) {
  return Nacl.secretbox.open(encryptedMessage, nonce, authKey)
}

function getEphemKeys() {
  return Nacl.box.keyPair()
}

//2 way asymmetric encryption
function asymmetricEncrypt(ephemeralKeyPair, receiverPublicKey, msgParams) {
  const pubKeyUInt8Array = NaclUtil.decodeBase64(receiverPublicKey)
  const msgParamsUInt8Array = NaclUtil.decodeUTF8(msgParams)
  const nonce = Nacl.randomBytes(Nacl.box.nonceLength)
  const encryptedMessage = Nacl.box(
    msgParamsUInt8Array,
    nonce,
    pubKeyUInt8Array,
    ephemeralKeyPair.secretKey
  )
  return {
    ciphertext: NaclUtil.encodeBase64(encryptedMessage),
    ephemPubKey: NaclUtil.encodeBase64(ephemeralKeyPair.publicKey),
    nonce: NaclUtil.encodeBase64(nonce),
  }
}

/* Decrypt a message with a base64 encoded secretKey (privateKey) */
function asymmetricDecrypt(ephemeralSecretKey, publicKey, encryptedData) {
  const nonce = NaclUtil.decodeBase64(encryptedData.nonce)
  const ciphertext = NaclUtil.decodeBase64(encryptedData.ciphertext)
  const decryptedMessage = Nacl.box.open(
    ciphertext,
    nonce,
    NaclUtil.decodeBase64(publicKey),
    ephemeralSecretKey
  )
  return NaclUtil.encodeUTF8(decryptedMessage)
}

function parseToken(token) {
  let base64 = token.replace(/-/g, "+").replace(/_/g, "/")
  const pad = base64.length % 4
  if (pad) {
    if (pad === 1) {
      throw new Error(
        "InvalidLengthError: Input base64url string is the wrong length to determine padding"
      )
    }
    base64 = base64 + new Array(5 - pad).join("=")
  }
  const jsonPayload = atob(base64)

  return JSON.parse(jsonPayload)
}

function getUserInfo() {
  const jwt = localStorage.getItem("token")
  return jwt ? parseToken(jwt.split(".")[1]) : null
}
