import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  submissions: { [key: string]: any[] }
  count: number
}

const initialState: InitialState = {
  submissions: {},
  count: 0,
}

export const {
  reducer: peebaDirectReducer,
  actions: {
    ADMIN_PEEBA_DIRECT_LIST_SUBMISSIONS,
    ADMIN_PEEBA_DIRECT_CHANGE_STATUS,
    ADMIN_PEEBA_DIRECT_EDIT,
  },
} = createSlice({
  name: 'REFERRAL',
  initialState,
  reducers: {
    ADMIN_PEEBA_DIRECT_LIST_SUBMISSIONS(
      state,
      action: PayloadAction<{
        submissions: { [key: string]: any[] }
        count: number
      }>
    ) {
      state.submissions = {
        ...state.submissions,
        ...action.payload.submissions,
      }
      state.count = action.payload.count
    },
    ADMIN_PEEBA_DIRECT_CHANGE_STATUS(
      state,
      action: PayloadAction<{
        id: number
        status: string
        page: number
      }>
    ) {
      let submissions = [...state.submissions[action.payload.page]]
      let submission = submissions.find((r) => r.id === action.payload.id)
      submission.peeba_direct_status = action.payload.status

      state.submissions = {
        ...state.submissions,
        [action.payload.page]: submissions,
      }
    },
    ADMIN_PEEBA_DIRECT_EDIT(
      state,
      action: PayloadAction<{
        id: any
        website: string
        page: any
      }>
    ) {
      let submissions = [...state.submissions[action.payload.page]]
      let submission = submissions.find((r) => r.id === action.payload.id)
      submission.widget_website = action.payload.website

      state.submissions = {
        ...state.submissions,
        [action.payload.page]: submissions,
      }
    },
  },
})

export const getSubmissions =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/peeba-direct'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_PEEBA_DIRECT_LIST_SUBMISSIONS({
              submissions: { [params.page]: response.data.result },
              count: response.data.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const changeStatus =
  (payload): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/peeba-direct'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_PEEBA_DIRECT_CHANGE_STATUS({
              id: payload.id,
              status: payload.status,
              page: payload.page,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const editWebsite =
  (payload, setOpenForm): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/peeba-direct/edit'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADMIN_PEEBA_DIRECT_EDIT({
              id: payload.id,
              website: payload.website,
              page: payload.page,
            })
          )
          setOpenForm(false)
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
