import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { history, crypt, httpService } from "../helpers"

let nonce = localStorage.getItem("nonce")
let auth = localStorage.getItem("auth")

interface InitialState {
  loggedIn?: boolean
  auth?: string | null
  nonce?: string | null
}

const initialState: InitialState = auth ? { loggedIn: true, auth, nonce } : {}

export const {
  reducer: authReducer,
  actions: { LOGIN_SUCCESS, LOGOUT_SUCCESS },
} = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    LOGIN_SUCCESS(
      state,
      action: PayloadAction<{ auth: string; nonce: string }>
    ) {
      const { auth, nonce } = action.payload
      state = {
        loggedIn: true,
        auth: auth,
        nonce: nonce,
      }
    },
    LOGOUT_SUCCESS(state) {
      state.auth = null
    },
  },
})

export const login = (username, password, onFailure) => (dispatch) => {
  httpService.get("/login", "stream").then((response: any) => {
    let payload = {
      username: username,
      password: crypt.hash(password),
    }

    const ephemeralKeyPair = crypt.getEphemKeys()
    const publicKey = response.data
    httpService
      .post(
        "/login/admin",
        crypt.asymmetricEncrypt(
          ephemeralKeyPair,
          publicKey,
          JSON.stringify(payload)
        )
      )
      .then((response: any) => {
        if (response) {
          let decrypted = JSON.parse(
            crypt.asymmetricDecrypt(
              ephemeralKeyPair.secretKey,
              publicKey,
              response.data
            )
          )
          localStorage.setItem("token", decrypted.token)
          localStorage.setItem("nonce", decrypted.nonce)
          localStorage.setItem("auth", decrypted.auth)
          localStorage.setItem("login", Date.now().toString())
          const userInfo = JSON.parse(atob(decrypted.token.split(".")[1]))
          localStorage.setItem("language_code", userInfo.user.language_code)

          dispatch(LOGIN_SUCCESS(response.data))
          history.push("/home")
        } else {
          onFailure()
        }
      })
  })
}
export const logout = () => (dispatch) => {
  localStorage.removeItem("auth")
  localStorage.removeItem("nonce")
  localStorage.removeItem("token")
  localStorage.removeItem("login")
  dispatch(LOGOUT_SUCCESS())
  history.push("/")
}

// DOESNT EXIST in actions/reducer
export const forgotPassword = (payload) => (dispatch) => {
  httpService.post("/login/forgotPassword", payload).then((response: any) => {
    if (response) {
      // dispatch(FORGOT_PASSWORD_EMAIL_SENT({}))
    }
  })
}
