import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  shippers: any[]
}

const initialState: InitialState = {
  shippers: [],
}

export const {
  reducer: shipperReducer,
  actions: { LIST_SHIPPER, ADD_SHIPPER, EDIT_SHIPPER },
} = createSlice({
  name: 'SHIPPER',
  initialState,
  reducers: {
    LIST_SHIPPER(
      state,
      action: PayloadAction<{
        shippers: []
      }>
    ) {
      console.log(action.payload.shippers)
      state.shippers = action.payload.shippers
    },
    ADD_SHIPPER(
      state,
      action: PayloadAction<{
        shipper: any
      }>
    ) {
      state.shippers.push(action.payload.shipper)
    },
    EDIT_SHIPPER(
      state,
      action: PayloadAction<{
        shipper: any
      }>
    ) {
      let editIndex = state.shippers.findIndex(
        (shipper) => shipper.id === action.payload.shipper.id
      )
      state.shippers[editIndex] = action.payload.shipper
    },
  },
})

const getShipper = (): AppThunk => (dispatch) => {
  console.log('called')
  let apiEndpoint = '/admin/shipper'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          LIST_SHIPPER({
            shippers: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const addShipper =
  (payload, setEditShipperOpen): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/shipper'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            ADD_SHIPPER({
              shipper: response.data.result,
            })
          )
          setEditShipperOpen(false)
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const editShipper =
  (payload, id, setEditShipperOpen): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/shipper/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            EDIT_SHIPPER({
              shipper: payload,
            })
          )
          setEditShipperOpen(false)
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const shipperRedux = {
  getShipper,
  addShipper,
  editShipper,
}
