import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  boards: any[]
  count: number
  current_board: any
}

const initialState: InitialState = {
  boards: [],
  count: 0,
  current_board: { retailers: [] },
}

export const {
  reducer: boardReducer,
  actions: {
    LIST_BOARDS,
    CREATE_BOARD,
    DELETE_BOARD,
    GET_BOARD_BY_ID,
    ADD_BOARD_PRODUCT,
    DELETE_PRODUCT,
    ADD_BOARD_RETAILER,
    DELETE_RETAILER,
    EDIT_BOARD,
  },
} = createSlice({
  name: 'BOARD',
  initialState,
  reducers: {
    LIST_BOARDS(
      state,
      action: PayloadAction<{
        boards: any[]
        count: number
      }>
    ) {
      state.boards = action.payload.boards
      state.count = action.payload.count
    },
    CREATE_BOARD(
      state,
      action: PayloadAction<{
        board: any
        user: any
      }>
    ) {
      let list = [...state.boards]
      let board: any = action.payload.board
      board.user = action.payload.user
      list.unshift(board)
      state.boards = list
    },
    DELETE_BOARD(
      state,
      action: PayloadAction<{
        board_id: number
      }>
    ) {
      let boards = [...state.boards]
      boards = boards.filter((b) => b.id !== action.payload.board_id)
      state.boards = boards
    },
    GET_BOARD_BY_ID(
      state,
      action: PayloadAction<{
        board: any
        retailers: any[]
      }>
    ) {
      state.current_board = action.payload.board
      state.current_board.retailers = action.payload.retailers
    },
    ADD_BOARD_PRODUCT(
      state,
      action: PayloadAction<{
        board_product: any
        product: any
      }>
    ) {
      let currentBoard = { ...state.current_board }
      let products = currentBoard.board_template_products
      action.payload.board_product.product = action.payload.product
      products.unshift(action.payload.board_product)
      currentBoard.board_template_products = products
      state.current_board = currentBoard
    },
    DELETE_PRODUCT(
      state,
      action: PayloadAction<{
        product_id: number
      }>
    ) {
      let board: any = { ...state.current_board }
      let products = board.board_template_products
      products = products.filter(
        (b) => b.product_id !== action.payload.product_id
      )
      board.board_template_products = products
      state.current_board = board
    },
    ADD_BOARD_RETAILER(
      state,
      action: PayloadAction<{
        retailer: any
        alreadyExists: boolean
        board: any
      }>
    ) {
      if (!action.payload.alreadyExists) {
        let board: any = { ...state.current_board }
        let retailers = board.retailers
        let retailer: any = { ...action.payload.retailer }
        retailer.boards = [action.payload.board]
        retailers.push(retailer)
        board.retailers = retailers
        state.current_board = board
      }
    },
    DELETE_RETAILER(
      state,
      action: PayloadAction<{
        retailer_id: number
      }>
    ) {
      let board: any = { ...state.current_board }
      let retailers = board.retailers
      retailers = retailers.filter((b) => b.id !== action.payload.retailer_id)
      board.retailers = retailers
      state.current_board = board
    },
    EDIT_BOARD(
      state,
      action: PayloadAction<{
        payload: any
      }>
    ) {
      let board: any = { ...state.current_board }
      board.name = action.payload.payload.board_name
      state.current_board = board
    },
  },
})

export const getBoards = (params): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board'
  httpService
    .get(apiEndpoint, 'json', params)
    .then((response: any) => {
      if (response.data.success) {
        console.log(response.data)
        dispatch(
          LIST_BOARDS({
            boards: response.data.result,
            count: response.data.count,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const createBoard = (payload, onSuccess): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          CREATE_BOARD({
            board: response.data.result,
            user: response.data.user,
          })
        )
        onSuccess()
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteBoard = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/' + id
  httpService
    .del(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_BOARD({
            board_id: id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getBoardById = (id): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/' + id
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          GET_BOARD_BY_ID({
            board: response.data.result,
            retailers: response.data.retailers,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addBoardProduct = (
  payload,
  onSuccess,
  setErrorMessage
): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/product'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          ADD_BOARD_PRODUCT({
            board_product: response.data.result,
            product: response.data.product,
          })
        )
        if (response.data.result !== null) onSuccess()
        else setErrorMessage("Product with above ID doesn't exist")
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteProduct = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/product'
  httpService
    .del(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_PRODUCT({
            product_id: payload.product_id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addBoardRetailer = (
  payload,
  onSuccess,
  setErrorMessage
): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/retailer'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        console.log(response.data)
        dispatch(
          ADD_BOARD_RETAILER({
            retailer: response.data.retailer,
            alreadyExists: response.data.alreadyExists,
            board: response.data.board,
          })
        )
        if (response.data.retailer !== null) onSuccess()
        else setErrorMessage("Retailer with above ID doesn't exist")
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteRetailer = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/retailer'
  httpService
    .del(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          DELETE_RETAILER({
            retailer_id: payload.retailer_id,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editBoard = (id, payload, onSuccess): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/board/' + id
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          EDIT_BOARD({
            payload: payload,
          })
        )
        onSuccess()
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
