import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  current_return: any
  returns: any
  count: number
}

const initialState: InitialState = {
  current_return: {},
  returns: {},
  count: 0,
}

export const {
  reducer: returnsReducer,
  actions: {
    RESET_RETURNS,
    LIST_RETURNS,
    GET_RETURN_BY_ID,
    EDIT_RETURN_ITEM,
    REJECT_RETURN,
    APPROVE_RETURN,
    RESOLVE_RETURN,
    REFUND_ONLY_RETURN,
  },
} = createSlice({
  name: 'RETURNS',
  initialState,
  reducers: {
    RESET_RETURNS(state, action: PayloadAction<{}>) {
      state = initialState
    },
    LIST_RETURNS(
      state,
      action: PayloadAction<{ returns: any; count: number }>
    ) {
      state.returns = action.payload.returns
      state.count = action.payload.count
    },
    GET_RETURN_BY_ID(state, action: PayloadAction<{ result: any }>) {
      console.log(action.payload.result)
      state.current_return = {
        ...state.current_return,
        ...action.payload.result,
      }
    },
    EDIT_RETURN_ITEM(state, action: PayloadAction<{ return_item: any }>) {
      const return_items = state.current_return!.return_items.map(
        (return_item) => {
          if (return_item.id !== action.payload.return_item.id) {
            return return_item
          }
          return {
            ...return_item,
            quantity: action.payload.return_item.quantity,
          }
        }
      )
      state.current_return!.return_items = return_items
    },
    REJECT_RETURN(state, action: PayloadAction<{}>) {
      state.current_return = {
        ...state.current_return,
        status: 'rejected',
      }
    },
    APPROVE_RETURN(state, action: PayloadAction<{}>) {
      state.current_return = {
        ...state.current_return,
        status: 'approved',
      }
    },
    RESOLVE_RETURN(state, action: PayloadAction<{}>) {
      state.current_return = {
        ...state.current_return,
        status: 'resolved',
      }
    },
    REFUND_ONLY_RETURN(state, action: PayloadAction<{}>) {
      state.current_return = {
        ...state.current_return,
        status: 'refund-only',
      }
    },
  },
})

const resetReturns = (): AppThunk => (dispatch) => {
  dispatch(RESET_RETURNS({}))
}

const getReturns =
  (params): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns'
    httpService
      .get(apiEndpoint, 'json', params)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            LIST_RETURNS({
              returns: { [params.page]: response.data.result.rows },
              count: response.data.result.count,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const getReturnById =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_RETURN_BY_ID({
              result: response.data.result,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const approveReturn =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/' + id + '/approve'
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(APPROVE_RETURN({}))
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
const rejectReturn =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/' + id + '/reject'
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(REJECT_RETURN({}))
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const resolveReturn =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/' + id + '/resolve'
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(RESOLVE_RETURN({}))
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const refundOnlyReturn =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/' + id + '/refund-only'
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(REFUND_ONLY_RETURN({}))
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const editReturnItem =
  (id, payload, onSuccess): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/returns/return_item/' + id
    httpService
      .put(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            EDIT_RETURN_ITEM({
              return_item: payload,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const returnsRedux = {
  resetReturns,
  getReturns,
  getReturnById,
  approveReturn,
  rejectReturn,
  resolveReturn,
  editReturnItem,
  refundOnlyReturn,
}
