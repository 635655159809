import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'

import { Provider } from 'react-redux'

import * as serviceWorker from './serviceWorker'
import store from './config/store'

// TODO? add a logger too with redux-logger and createLogger?

const render = () => {
  const App = require('./App').default
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
