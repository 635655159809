import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../config/store'
import { httpService } from '../helpers'
import { logout } from './auth'

interface InitialState {
  organizations: any[]
  brands: any[],
  current_organization: any
}

const initialState: InitialState = {
  organizations: [],
  brands: [],
  current_organization: undefined,
}

export const {
  reducer: organizationReducer,
  actions: { 
    LIST_ORGANIZATIONS,
    ADD_ORGANIZATION,
    EDIT_ORGANIZATION,
    GET_ORGANIZATION_DETAILS,
    ADD_ORGANIZATION_USER,
    UPDATE_ORGANIZATION_USER,
  },
} = createSlice({
  name: 'ORGANIZATION',
  initialState,
  reducers: {
    LIST_ORGANIZATIONS(
      state,
      action: PayloadAction<{
        organizations: []
        brands: []
      }>
    ) {
      state.organizations = action.payload.organizations
      state.brands = action.payload.brands
    },
    ADD_ORGANIZATION(
      state,
      action: PayloadAction<{
        organization: any
      }>
    ) {
      state.organizations.push(action.payload.organization)
    },
    EDIT_ORGANIZATION(
      state,
      action: PayloadAction<{
        organization: any
      }>
    ) {
      let editIndex = state.organizations.findIndex(
        (org) => org.id === action.payload.organization.id
      )
      state.organizations[editIndex] = action.payload.organization
      state.current_organization = {
        ...state.current_organization,
        ...action.payload.organization
      }
    },
    GET_ORGANIZATION_DETAILS(
      state,
      action: PayloadAction<{
        current_organization: any;
        order_notification_email: any;
        region_id: any;
      }>
    ) {
      state.current_organization = {
        ...action.payload.current_organization,
        order_notification_email: action.payload.order_notification_email,
        region_id: action.payload.region_id,
      }
    },
    ADD_ORGANIZATION_USER(state, action: PayloadAction<{ contact: any; user: any }>) {
      if (!state.current_organization) {
        return
      }
      let users = state.current_organization.users
      users.push({
        ...action.payload.user,
        created_at: new Date()
      })
      state.current_organization.users = users
    },
    UPDATE_ORGANIZATION_USER(state, action: PayloadAction<{ user: any }>) {
      if (!state.current_organization) {
        return
      }
      let users = state.current_organization.users
      for (let i = 0; i < users.length; i++) {
        if (users[i].username === action.payload.user.email) {
          users[i].first_name = action.payload.user.first_name
          users[i].last_name = action.payload.user.last_name
          users[i].role_id = action.payload.user.role_id
          break
        }
      }
      state.current_organization.users = users
    },
  },
})

export const loginAsWholeSaler =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/organization/login/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          if (window.location.host.match(/static-admin/)) {
            window.open('https://brand.peeba.com/?token=' + response.data.result)
          } else if (window.location.host.match(/dev.admin.peeba.xyz/)){
            window.open('https://dev.brand.peeba.xyz/?token=' + response.data.result)
          } else {
            window.open('https://brand-client-staging-j275taoz7q-de.a.run.app/?token=' + response.data.result)
          }
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

const getOrganizations = (): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/organization'
  httpService
    .get(apiEndpoint)
    .then((response: any) => {
      if (response.data.success) {
        const availableBrands = response.data?.result?.brands?.map((brand) => {
          return {
            id: brand.id,
            value: brand.name,
            label: brand.name,
            users: brand.users,
            currency_id: brand.currency_id,
          }
        })
        dispatch(
          LIST_ORGANIZATIONS({
            organizations: response.data?.result?.organizations,
            brands: availableBrands,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const addOrganization = (payload): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/organization'
  httpService
    .post(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        dispatch(
          ADD_ORGANIZATION({
            organization: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editOrganization = (payload, id, onSuccess): AppThunk => (dispatch) => {
  let apiEndpoint = '/admin/organization/' + id
  httpService
    .put(apiEndpoint, payload)
    .then((response: any) => {
      if (response.data.success) {
        onSuccess()
        dispatch(
          EDIT_ORGANIZATION({
            organization: response.data.result,
          })
        )
      } else if (response.data.logout) {
        dispatch(logout())
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getOrganizationById =
  (id): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/organization/' + id
    httpService
      .get(apiEndpoint)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            GET_ORGANIZATION_DETAILS({
              current_organization: response.data.result.organization,
              order_notification_email: response.data.result?.defaultCompany?.order_notification_email,
              region_id: response.data.result?.defaultCompany?.region_id,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const addUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/organization/user'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            ADD_ORGANIZATION_USER({
              user: response.data.result,
              contact: response.data.contact,
            })
          )
        } else if (response.data.mailer) {
          onFailure(response.data.error)
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const updateUser =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/organization/update-user'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
          dispatch(
            UPDATE_ORGANIZATION_USER({
              user: payload,
            })
          )
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const resetPassword =
  (payload, onSuccess, onFailure): AppThunk =>
  (dispatch) => {
    let apiEndpoint = '/admin/organization/reset'
    httpService
      .post(apiEndpoint, payload)
      .then((response: any) => {
        if (response.data.success) {
          onSuccess()
        } else if (response.data.logout) {
          dispatch(logout())
        } else {
          onFailure(response.data.error)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

export const organizationRedux = {
  getOrganizations,
  addOrganization,
  editOrganization,
  addUser,
  updateUser,
  resetPassword,
}
