import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'

import { login } from '../../redux/auth'
import { history } from '../../helpers'
import FlexlaneLogo from '../../images/FlexlaneLogo'

import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { useDispatch } from 'react-redux'

const useLoginStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '100vh',
  },
  paper: {
    padding: '40px 80px',
    border: '1px solid #E3E3E3',
    borderRadius: 8,
    width: 360,
    margin: 'auto',
    textAlign: 'center',
  },
  forgotPassword: {
    textAlign: 'right',
    lineHeight: '36px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFF5F8',
    height: 80,
    marginTop: 'auto',
    width: '100%',
  },
}))

const initialState = {
  values: {
    username: '',
    password: '',
  },
  errors: {
    username: false,
    password: false,
  },
  error: '',
}

const Login = () => {
  const classes = useLoginStyles()

  const [localState, setLocalState] = useState(initialState)
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/home')
    }
  }, [])

  const handleChange = (key) => (event) => {
    const value = event.target.value || ''
    setLocalState((s) => ({
      ...s,
      errors: { ...s.errors, [key]: false },
      values: { ...s.values, [key]: value },
    }))
  }

  const loginHandler = () => {
    const { values } = localState
    if (validate()) {
      dispatch(login(values.username.toLowerCase(), values.password, setError))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      loginHandler()
    }
  }
  const validate = () => {
    let valid = true
    let validationErrors = {} as typeof initialState.errors
    const { values } = localState
    if (values.username === '') {
      validationErrors.username = true
      valid = false
    }
    if (values.password === '') {
      validationErrors.password = true
      valid = false
    }
    setLocalState((s) => ({ ...s, errors: validationErrors }))
    return valid
  }
  const setError = () => {
    setLocalState((s) => ({
      ...s,
      error: 'Username or password incorrect',
    }))
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FlexlaneLogo width={26} height={40} fill="#0DA0FB" />
            <Typography variant="h4">
              <strong>
                <Translate id="welcome_back" />
              </strong>
            </Typography>
            <Typography variant="subtitle2">
              <Translate id="use_login_credentials" />
            </Typography>
            {localState.error ? (
              <Typography color="error">
                <Translate id="username_or_password_incorrect" />
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Translate>
              {({ translate }) => (
                <TextField
                  fullWidth
                  label={translate('username')}
                  variant="outlined"
                  autoFocus
                  onKeyDown={handleKeyPress}
                  value={localState.values.username}
                  onChange={handleChange('username')}
                  InputLabelProps={{ shrink: true }}
                  error={localState.errors.username}
                  helperText={
                    localState.errors.username
                      ? translate('field_is_required')
                      : ''
                  }
                />
              )}
            </Translate>
          </Grid>
          <Grid item xs={12}>
            <Translate>
              {({ translate }) => (
                <TextField
                  fullWidth
                  label={translate('password')}
                  variant="outlined"
                  type="password"
                  onKeyDown={handleKeyPress}
                  value={localState.values.password}
                  onChange={handleChange('password')}
                  InputLabelProps={{ shrink: true }}
                  error={localState.errors.password}
                  helperText={
                    localState.errors.password
                      ? translate('field_is_required')
                      : ''
                  }
                />
              )}
            </Translate>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
              }
              label={
                <Typography variant="body2">
                  <Translate id="remember_password" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={5} className={classes.forgotPassword}>
            <Link href="/forgotpassword" variant="subtitle1" color="secondary">
              <Translate id="forgot_password" />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={loginHandler}
              fullWidth
            >
              <Translate id="login" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              <Translate id="dont_have_an_account" />{' '}
              <Link href="#" variant="subtitle1" color="secondary">
                <Translate id="sign_up" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <footer className={classes.footer}>
        <div>
          <Typography variant="subtitle2">
            <Translate id="flexlane_all_rights_reserved" />
          </Typography>
        </div>
      </footer>
    </div>
  )
}

export default Login
